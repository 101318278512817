import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import { HistoryLocation } from '@reach/router'
import useGlobalText from '@system/hooks/useGlobalText'

import JobDetail from '@components/modules/content/M009-JobDetail'
import Contact from '@components/modules/content/M003-Contact'
import VerticalSpacer from '@components/core/spacer'
import Teaser, {
  TeaserPageProps,
} from '@components/modules/content/M017-Teaser'

export interface JobPageProps {
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  data: {
    job: {
      id: string
      name: string
      slug: string
      jobId: string
      unit?: string
      office?: string
      department?: string
      schedule?: string
      type?: string
      jobDescriptions: Array<{
        name: string
        value: string
      }>
      officePage?: TeaserPageProps
    }
  }
}

export default function JobDetailPage({
  data,
  pageContext,
}: JobPageProps): ReactElement {
  const job = data.job
  const { getText } = useGlobalText()

  return (
    <>
      <JobDetail
        id={job.id}
        pageContext={pageContext}
        theme="light"
        name={job.name}
        slug={job.slug}
        jobId={job.jobId}
        unit={job.unit}
        office={job.office}
        department={job.department}
        schedule={job.schedule}
        type={job.type}
        jobDescriptions={job.jobDescriptions}
      />

      <Contact
        pageContext={pageContext}
        theme="grey"
        type="social"
        headline={getText('page.jobDetail.contact.headline')}
        socialNetwork={['LinkedIn', 'XING', 'Facebook', 'Twitter', 'E-Mail']}
      />

      {job.officePage && (
        <>
          <VerticalSpacer theme="dark" sizeDesktop={120} sizeMobile={32} />
          <Teaser
            theme="dark"
            type="half"
            headline={getText('page.jobDetail.teaser.headline')}
            rows={[
              {
                align: 'left',
                pages: [job.officePage],
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export const pageQuery = graphql`
  query JobDetailBySlugID($id: String!, $locale: String!) {
    job(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      name
      slug
      jobId
      unit
      office
      department
      schedule
      type
      jobDescriptions {
        name
        value
      }
      officePage {
        teaserTitle
        teaserCopy {
          raw
        }
        teaserImageLandscape {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 520
            height: 292
            quality: 90
          )
          description
          title
        }
        fields {
          fullPath
          isExternal
        }
      }
    }
  }
`
