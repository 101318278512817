import React, { ReactElement, useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { defaultLang } from '@system/translations'
import { Job } from '@/types/job'
import clsx from 'clsx'
import { InView } from 'react-intersection-observer'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import useGlobalText from '@system/hooks/useGlobalText'
import Container from '@components/modules/global/container'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import Button from '@components/core/button'
import FontSize from '@config/theme/definitions/fontSize'
import { useStickyHeader } from '@system/hooks/index'

const useStyles = makeStyles((theme) => ({
  jobDetailRoot: {
    padding: theme.spacing(31, 0, 10),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(37, 0, 22),
    },
  },
  jobDetailHeadline: {
    marginBottom: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  jobDetailLinkTop: {
    marginBottom: theme.spacing(10),
  },
  jobDetailLinkBottom: {
    marginTop: theme.spacing(10),
  },
  jobDetailMetaEntry: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
  },
  jobDetailMetaHead: {
    ...theme.typography.intrometahead,
  },
  jobDetailMetaBody: {
    ...theme.typography.intrometabody,
  },
  jobDetailDescription: {
    margin: theme.spacing(10, 0),
    '&:first-child': {
      marginTop: theme.spacing(2),
    },
    '&:last-child': {
      marginBottom: theme.spacing(2),
    },
  },
  jobDetailOutro: {
    marginBottom: 0,
  },

  stickyJobsHeaderWrapper: {
    width: '100%',
    position: 'fixed',
    opacity: 0,
    transition: 'all .5s',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      transform: 'translate(0, +120px)',
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.secondary.main,
      top: 0,
      transform: 'translate(0, -120px)',
    },
  },

  stickyHeaderShow: {
    opacity: 1,
    transform: 'translate(0, 0px)',
  },
  stickyHeaderShift: {
    transform: 'translate(0, +72px)',
  },

  stickyJobsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  stickyJobDetailHeadline: {
    fontSize: FontSize['xl'],
    marginBottom: 0,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

export type JobDetailProps = DBN.IReactDefaultProps &
  Partial<Job> & {
    pageContext?: DBN.PageHelpers.PageContext
    theme?: string
  }

export default function JobDetail({
  pageContext,
  theme,
  name,
  slug,
  unit,
  office,
  department,
  schedule,
  jobDescriptions,
}: JobDetailProps): ReactElement {
  const { toggleStickyHeader } = useStickyHeader()

  const classes = useStyles()
  const { getText } = useGlobalText()
  const locale = pageContext?.locale || defaultLang

  const [inViewState, setInViewState] = useState(false)
  const [bottomInViewState, setBottomInViewState] = useState(false)
  const [descriptionInViewState, setDescriptionInViewState] = useState(false)

  useEffect(() => {
    toggleStickyHeader(
      !inViewState && !bottomInViewState && descriptionInViewState
    )
  }, [inViewState, bottomInViewState, descriptionInViewState])

  return (
    <Module theme={theme} className={classes.jobDetailRoot}>
      <div
        className={clsx(classes.stickyJobsHeaderWrapper, {
          [classes.stickyHeaderShow]:
            !inViewState && !bottomInViewState && descriptionInViewState,
        })}
      >
        <Container type="nomargin">
          <div className={classes.stickyJobsHeader}>
            {name && (
              <Headline level={1} className={classes.stickyJobDetailHeadline}>
                {name}
              </Headline>
            )}
            <Button
              to={
                locale === defaultLang
                  ? `/jobs/application/${slug}`
                  : `/${locale}/jobs/application/${slug}`
              }
            >
              {getText('module.jobDetail.apply') || 'Apply now'}
            </Button>
          </div>
        </Container>
      </div>
      <Container type="nomargin">
        {name && (
          <Headline level={1} className={classes.jobDetailHeadline}>
            {name}
          </Headline>
        )}
        <InView threshold={0} delay={100} onChange={setInViewState}>
          {({ ref }) => (
            <div ref={ref}>
              <div className={classes.jobDetailLinkTop}>
                <Button
                  to={
                    locale === defaultLang
                      ? `/jobs/application/${slug}`
                      : `/${locale}/jobs/application/${slug}`
                  }
                >
                  {getText('module.jobDetail.apply') || 'Apply now'}
                </Button>
              </div>
            </div>
          )}
        </InView>

        <InView threshold={0} delay={100} onChange={setDescriptionInViewState}>
          {({ ref }) => (
            <Grid
              container
              justifyContent="space-between"
              spacing={8}
              ref={ref}
            >
              <Grid item xs={12} md={6}>
                {jobDescriptions?.map((description, index) => (
                  <div className={classes.jobDetailDescription} key={index}>
                    {description.name && (
                      <Headline level={3}>{parse(description.name)}</Headline>
                    )}
                    {description.value && parse(description.value)}
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} md={5}>
                {unit && unit !== 'DEFAULT' && (
                  <div className={classes.jobDetailMetaEntry}>
                    <div className={classes.jobDetailMetaHead}>
                      {getText('module.jobDetail.unit') || 'Unit'}
                    </div>
                    <div className={classes.jobDetailMetaBody}>
                      {getText(`job.unit.${unit}`) || unit}
                    </div>
                  </div>
                )}
                {office && (
                  <div className={classes.jobDetailMetaEntry}>
                    <div className={classes.jobDetailMetaHead}>
                      {getText('module.jobDetail.office') || 'Location'}
                    </div>
                    <div className={classes.jobDetailMetaBody}>
                      {getText(`job.office.${office}`) || office}
                    </div>
                  </div>
                )}
                {schedule && (
                  <div className={classes.jobDetailMetaEntry}>
                    <div className={classes.jobDetailMetaHead}>
                      {getText('module.jobDetail.schedule') || 'Working hours'}
                    </div>
                    <div className={classes.jobDetailMetaBody}>
                      {getText(`job.schedule.${schedule}`) || schedule}
                    </div>
                  </div>
                )}
                {department && (
                  <div className={classes.jobDetailMetaEntry}>
                    <div className={classes.jobDetailMetaHead}>
                      {getText('module.jobDetail.department') || 'Department'}
                    </div>
                    <div className={classes.jobDetailMetaBody}>
                      {getText(`job.department.${department}`) || department}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </InView>

        <InView threshold={0} delay={100} onChange={setBottomInViewState}>
          {({ ref }) => (
            <div className={classes.jobDetailLinkBottom} ref={ref}>
              <Button
                to={
                  locale === defaultLang
                    ? `/jobs/application/${slug}`
                    : `/${locale}/jobs/application/${slug}`
                }
              >
                {getText('module.jobDetail.apply') || 'Apply now'}
              </Button>
            </div>
          )}
        </InView>
      </Container>
    </Module>
  )
}
